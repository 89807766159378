export class Keyboard {
  readonly keyMap = new Map<string, boolean>()
  private readonly mappings: { mapping: KeyboardMapping, action: Actions }[] = [];
  private readonly actionHandlers: { action: Actions; fn: () => void }[] = [];

  onKeyReleased(key: string) {
    //trigger any release actions here
    this.mappings.forEach((m) => {
      if (m.mapping.released !== key) {
        return;
      }
      const triggered = !m.mapping.down || this.keyMap.has(m.mapping.down)
      if (!triggered) {
        return;
      }
      console.log('trigger', m.action, 'from onKeyReleased')
      this.triggerActions(m.action)
    })
    this.keyMap.delete(key)
  }

  getKeysDown() {
    return Array.from(this.keyMap.keys())
  }

  private triggerActions(action: Actions) {
    this.actionHandlers.filter(a => a.action === action).forEach((t) => {
      t.fn()
    })
  }

  onKeyPressed(key: string) {
    //trigger any key down only
    this.mappings.forEach((m) => {
      if (m.mapping.down !== key || m.mapping.released) {
        return;
      }
      console.log('trigger', m.action, 'from onKeyPressed')
      this.triggerActions(m.action)
    })
    this.keyMap.set(key, true)
  }

  update() {
    this.mappings.forEach((m) => {
      if (!m.mapping.down || m.mapping.released) {
        return;
      }
      if (!this.keyMap.has(m.mapping.down)) {
        return;
      }
      this.triggerActions(m.action)
    })
  }

  isKeyDown(key: string) {
    return this.keyMap.has(key)
  }

  registerAction(action: Actions, mapping: KeyboardMapping) {
    this.mappings.push({mapping, action})
  }

  registerHandler(action: Actions, fn: () => void) {
    this.actionHandlers.push({action, fn})
  }
}

type KeyboardMapping = {
  down?: undefined;
  released: string;
} | {
  down: string;
  released: string;
} | {
  down: string;
  released?: undefined
}

export enum Actions {
  SaveScreenshot = 'SaveScreenshot',
  NewObject = 'NewObject',
  ToggleOutlineMode = 'ToggleOutlineMode',
  DeleteTargetObject = 'DeleteTargetObject',
  Undo = 'Undo',
  FreezeObject = 'FreezeObject',
  TogglePause = 'TogglePause',
  NextShapeType = 'NextShapeType',
  NextColor = 'NextColor',
  SwirlForce = 'SwirlForce',
  CompressionForce = 'CompressionForce',
  DecompressionForce = 'DecompressionForce',
  ScribbleMode = 'ScribbleMode',
}

let defaultKeyboard: Keyboard | null = null;
export const getKeyboard = () => {
  if (!defaultKeyboard) {
    defaultKeyboard = new Keyboard();
    defaultKeyboard.registerAction(Actions.SaveScreenshot, {released: 'S'})
    defaultKeyboard.registerAction(Actions.NewObject, {released: 'n'})
    defaultKeyboard.registerAction(Actions.ToggleOutlineMode, {released: 'O'})
    defaultKeyboard.registerAction(Actions.DeleteTargetObject, {released: 'Backspace'})
    defaultKeyboard.registerAction(Actions.DeleteTargetObject, {down: 'Shift', released: 'Backspace'})
    defaultKeyboard.registerAction(Actions.Undo, {released: 'Z'})
    defaultKeyboard.registerAction(Actions.FreezeObject, {released: 'F'})
    defaultKeyboard.registerAction(Actions.TogglePause, {released: 'P'})
    defaultKeyboard.registerAction(Actions.NextShapeType, {released: 'D'})
    defaultKeyboard.registerAction(Actions.SwirlForce, {down: '!'})
    defaultKeyboard.registerAction(Actions.DecompressionForce, {down: '#'})
    defaultKeyboard.registerAction(Actions.CompressionForce, {down: '@'})
    defaultKeyboard.registerAction(Actions.NextColor, {released: 'V'})
    defaultKeyboard.registerAction(Actions.ScribbleMode, {released: 'E'})
  }
  return defaultKeyboard;
}