// Calculate the radius of a circle consisting of N links of width widthLink and gap linkGap
// R = node radius
// r = R / sin((1/N*2pi)/2) / 2
export const computeRadius = ({linkGap, widthLink, N, p5}) => {
    return (linkGap + widthLink) / p5.sin(((1 / N) * p5.TWO_PI) / 2) / 2;
}

// Basically the reverse of computeRadius. Given a circle radius, calculate the number of nodes
// 1 / (( arcsin(R/2r)*2 ) / 2pi)
export const computeNumberOfNodesFromRadius = ({radius: r, linkGap, widthLink, p5}) => {
    if (r < linkGap + widthLink) throw new Error("Radius too small for linkGap and widthLink");
    const R = linkGap + widthLink;
    return Math.floor(1 / (p5.asin(R / (2 * r)) * 2 / p5.TWO_PI))
}