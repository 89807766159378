export class DebugInfo {
    info = {}

    addInfo({title, value}) {
        this.info[title] = value
    }

    clearInfo() {
        this.info = {}
    }

    getInfo() {
        return this.info
    }


    show() {
        const el = document.getElementById('debug-info')
        el.style.display = 'block'
    }

    hide() {
        const el = document.getElementById('debug-info')
        el.style.display = 'none'
    }

    writeToDom() {
        const info = this.getInfo()
        const infoDiv = document.getElementById('debug-info')
        if (!infoDiv) {
            return
        }
        infoDiv.innerHTML = ''
        Object.keys(info).forEach(key => {
            const value = info[key]
            const div = document.createElement('div')
            div.innerHTML = `${key}: ${value}`
            infoDiv.appendChild(div)
        })
    }
}