import Matter, {Body, Engine, Vector} from "matter-js";
import {getAllBodies} from "./swirlforce";
import {PRNGRand} from "../pw";
import P5 from "p5";

export const getCircleCollision = (engine: Engine, {radius, x, y}: {
  radius: number;
  x: number;
  y: number
}): Body | null => {
  var allBodies = getAllBodies(engine)
  const position = Vector.create(x, y)
  const boundingBox = Matter.Bounds.create(
      [
        {x: x - radius, y: y - radius},
        {x: x + radius, y: y + radius},
        {x: x - radius, y: y + radius},
        {x: x + radius, y: y - radius}
      ]
  )
  const distanceSquaredMax = radius * radius;
  const bodies = Matter.Query.region(allBodies, boundingBox)
  if (!bodies.length) {
    return null;
  }
  for (let i = 0; i < bodies.length; i++) {
    const body = bodies[i];

    const distanceSquared = Matter.Vector.magnitudeSquared(Matter.Vector.sub(body.position, position));
    if (distanceSquared < distanceSquaredMax) {
      return body
    }
  }
}

export const failedToFindUnoccupiedCircleError = 'failed to find unoccupied circle';
export const findUnoccupiedCircle = ({x: xx, y: yy, radius, engine, prng, p5}: {
  x: number;
  y: number;
  radius: number;
  engine: Engine; prng: PRNGRand; p5: P5
}) => {
  let xfound = xx;
  let yfound = yy;

  let i = 0;
  do {
    const colliding = getCircleCollision(engine, {
      x: xfound, y: yfound,
      radius
    })
    if (!colliding) {
      break;
    }
    xfound = prng.randomInt(0, p5.width)
    yfound = prng.randomInt(0, p5.width)
  } while (i++ < 1000)
  if (i >= 1000) {
    throw new Error(failedToFindUnoccupiedCircleError)
  }
  return [xfound, yfound]
}