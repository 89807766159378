class Sfc32 {
  constructor(uint128Hex: string) {
    let a = parseInt(uint128Hex.substring(0, 8), 16);
    let b = parseInt(uint128Hex.substring(8, 8), 16);
    let c = parseInt(uint128Hex.substring(16, 8), 16);
    let d = parseInt(uint128Hex.substring(24, 8), 16);
    this.next = function () {
      a |= 0;
      b |= 0;
      c |= 0;
      d |= 0;
      let t = (((a + b) | 0) + d) | 0;
      d = (d + 1) | 0;
      a = b ^ (b >>> 9);
      b = (c + (c << 3)) | 0;
      c = (c << 21) | (c >>> 11);
      c = (c + t) | 0;
      return (t >>> 0) / 4294967296;
    };
  }

  public readonly next: () => number;

};

export class PRNGRand {
  private useA: boolean;
  private prngA: Sfc32
  private prngB: Sfc32

  constructor(hash?: string) {
    let queryHash = new URLSearchParams(window.location.search).get('hash')
    hash = queryHash || hash?.toString() || generateTxHash()
    hash = hash.toUpperCase()
    const regex = new RegExp('[0-9A-F]{64}')
    if (!regex.test(hash)) {
      console.error("Bad hash", hash)
      throw Error("Bad hash: " + hash)
    }
    this.useA = false;

    // seed prngA with first half hash
    this.prngA = new Sfc32(hash.substring(2, 32));
    // seed prngB with second half of hash
    this.prngB = new Sfc32(hash.substring(34, 32));
    for (let i = 0; i < 1e6; i += 2) {
      this.prngA.next();
      this.prngB.next();
    }
    this.grand = () => {
      this.useA = !this.useA;
      return this.useA ? this.prngA.next() : this.prngB.next();
    }
  }

  public readonly grand: () => number;

  /**
   * Create a floating point random number between [0,1)
   * @param lo optional lower bound (default 0). When specified returns [lo,1)
   * @param hi optional upper bound (default 1). When included with lo returns [lo,hi)
   * @returns {number|*}
   */
  random(lo?: number, hi?: number) {
    if (lo === undefined && hi === undefined) return this.grand()
    if (hi === undefined && lo !== undefined) {
      return this.grand() * lo
    }
    return this.grand() * (hi - lo) + lo
  }

  boolean() {
    return this.grand() >= 0.5
  }

  /**
   * Return a random integer between [lo,hi] where lo and hi are inclusive.
   */
  randomInt(lo?: number, hi?: number) {
    if (lo === undefined && hi === undefined) {
      throw new Error("randomInt requires two arguments")
    }
    if (hi === undefined) {
      return Math.floor(this.random(lo + 1))
    }
    return Math.floor(this.random(lo, hi + 1))
  }

  /**
   * Return a random element from an array.
   */
  randomList<T>(list: T[] | Readonly<T[]>) {
    return list[this.randomInt(0, list.length - 1)]
  }

  /**
   * Return one of the keys based on the value probability versus the sum of
   * all other key values.
   *
   * Example:
   *
   * `randomWeighted(new Map([['Low', .1], ['Medium', .3], ['High', .6]]))`
   *  has a 10% chance of returning 'Low',
   *  a 30% chance of returning 'Medium', and a 60% chance of returning
   *  'High'. Probability values do not need to add up to 1.
   */
  randomWeighted<K>(map: Map<K, number>): K {
    const keys = Array.from(map.keys());
    const totalSum = keys.reduce((acc, item) => acc + map.get(item), 0);
    let runningTotal = 0;
    const cumulativeValues = keys.map((key) => {
      const relativeValue = map.get(key) / totalSum;
      const cv = {
        key,
        value: relativeValue + runningTotal
      };
      runningTotal += relativeValue;
      return cv;
    });
    const r = this.random();
    return cumulativeValues.find(({key, value}) => r <= value).key;
  };
}

/**
 * Get reset PRNG instance.
 */
export const createPrng = () => {
  return new PRNGRand();
}

/**
 * Check if the work is rendering in the public works preview pipeline
 */
export const isPWPreview = () => {
  return new URLSearchParams(window.location.search).get('preview') === 'true'
}

/**
 * Check if the work is displayed on the public works site
 */
export const isPW = () => {
  return isPWPreview() || new URLSearchParams(window.location.search).get('publicworks') !== null
}

export const generateTxHash = () => {
  let alphabet = "0123456789ABCDEF"
  return Array(64).fill(0).map(_ => alphabet[(Math.random() * alphabet.length) | 0]).join('')
}

export const setWindowProperties = (key: string, attributes: object) => {
  if (typeof attributes !== 'object') {
    throw new Error('attributes should be an object')
  }
  (window as any)[key] = {...attributes};
}

/**
 * Set the NFT traits. Attributes are the NFT attributes seen on marketplaces. Traits are optional numeric
 * representations of attributes to expose to public works.
 */
export const setProperties = (attributes: any, traits = {}) => {
  setWindowProperties('attributes', attributes)
  setWindowProperties('traits', traits)
}

/**
 * Notify public works preview pipeline that rendering is complete.
 */
export const setPreviewReady = () => {
  (window as any)['previewReady'] = true;
}