import {deserializeWorld, serializeWorld} from "matter-js-serialize";
import Matter, {Engine, World} from "matter-js";
import {sessionIndexSchema} from "../state/session";
import {DBSchema, openDB, IDBPDatabase} from "idb";
import {compress, decompress} from "lz-string";
import {Palette} from "../palettes";


const sessionIndexKey = 'session-idx';
type Session = {
  id: string;
  palette?: Palette
}

interface MyDB extends DBSchema {
  'world-states': {
    key: string;
    value: {
      id: string;
      compressed: boolean;
      dateUnix: number;
      sessionId: string;//ref to sessions
      data: string;
    };
    indexes: { 'by-session-id-date': [string, number] };
  };
  sessions: {
    value: Session;
    key: string;
  };
}

class DB {

  constructor(private db: IDBPDatabase<MyDB>) {

  }

  static async open() {
    const db: IDBPDatabase<MyDB> = await openDB<MyDB>('sessionIndex', 1, {
      upgrade(db) {
        const sessionStore = db.createObjectStore('sessions', {
          keyPath: 'id'
        });

        const worldStatesStore = db.createObjectStore('world-states', {
          keyPath: 'id',
        });
        worldStatesStore.createIndex('by-session-id-date', ['sessionId', 'dateUnix'], {
          unique: false
        });
      },
    });
    return new DB(db)
  }

  async getSession(key: string) {
    return this.db.get('sessions', key)
  }

  async getAllWorldStates(sessionKey: string) {
    const out = await this.db.getAllFromIndex('world-states', 'by-session-id-date', [sessionKey, Date.now()])
    return out;
  }

  async getAllWorldStatesKeys(sessionKey: string) {
    var lowerBound = [sessionKey, 0];
    var upperBound = [sessionKey, Date.now()];
    var range = IDBKeyRange.bound(lowerBound, upperBound);
    const out = await this.db.getAllKeysFromIndex('world-states', 'by-session-id-date', range)
    return out;
  }

  async getWorldState(key: string) {
    const out = await this.db.get('world-states', key)
    return out;
  }

  async insertWorldState(opts: {
    id: string;
    compressed: boolean;
    dateUnix: number;
    sessionId: string;
    data: string;
  }) {
    await this.db.put('world-states', opts)
  }

  async createSession(param: Session) {
    await this.db.put('sessions', param)
  }
}

const getSessionIndex = (db: DB) => {
  const idx = localStorage.getItem(sessionIndexKey) || '{"sessions":[]}';
  const sessions = JSON.parse(idx)
  const indexOr = sessionIndexSchema.safeParse(sessions)
  if (!indexOr.success) {
    return {sessions: []}
  }

  return indexOr.data
}
export const storeWorldLocal = async (engine: Engine, sessionName: string, session: Omit<Session, 'id'>) => {
  const db = await DB.open()

  const sessDb = await db.getSession(sessionName)
  if (!sessDb) {
    await db.createSession({...session, id: sessionName})
  }

  const serializable = serializeWorld(engine.world)

  const now = new Date().toISOString()
  const instanceName = `${sessionName}-${now}`
  const compressed = compress(serializable);
  await db.insertWorldState({
    id: instanceName,
    compressed: true,
    dateUnix: Date.now(),
    sessionId: sessionName,
    data: compressed
  })
  // const serializer = Serializer.create()
  // Serializer.saveState(serializer, engine, key)
}

export const loadLoadToWorld = async (engine: Engine, sessionName: string, lookback = 0, beforeLoad?: () => void) => {
  const db = await DB.open()

  const keys = await db.getAllWorldStatesKeys(sessionName)
  if (!keys.length || lookback >= keys.length) {
    console.log('no keys')
    return
  }

  const key = keys[keys.length - 1 - lookback]

  const state = await db.getWorldState(key)
  if (!state) {
    console.log('no data item', key)
    return
  }
  const decompressed = decompress(state.data);
  const world = deserializeWorld(decompressed)
  Matter.Composite.clear(engine.world, false, true);

  if (world) {
    // @ts-ignore
    Matter.Engine.merge(engine, {
      world: world as unknown as World
    });
  }
  if (beforeLoad) {
    beforeLoad()
  }
  // add back the mouse constraint
  // const serializer = Serializer.create()
  // Serializer.loadState(serializer, engine, key)

}