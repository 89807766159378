export const rotate2d = function ({x, y}:{x:number;y:number}, radians:number) {
  const c = Math.cos(radians);
  const s = Math.sin(radians);
  return {
    x: x * c - y * s,
    y: x * s + y * c,
  };
  // x' = x cos θ − y sin θ
  // y' = x sin θ + y cos θ
};
