import Matter from 'matter-js';

export const getAllBodies = (engine, applyToComposites) => {
    let allBodies = [];
    if (Array.isArray(applyToComposites)) {
        allBodies = applyToComposites.map(c => Matter.Composite.allBodies(c)).flat()
    } else {
        allBodies = Matter.Composite.allBodies(engine.world);
    }
    return allBodies
}

export const applyDragForce = (engine, world, {force, position, body}) => {
    Matter.Body.applyForce(body, position, Matter.Vector.mult(force, 1))
    Matter.Sleeping.set(body, false);
}

export const applySwirlForce = (engine, world, {force, distance, position, applyToComposites}) => {
    let allBodies = getAllBodies(engine, applyToComposites)
    const forceBounds = Matter.Bounds.create(
        [
            {x: position.x - distance, y: position.y - distance},
            {x: position.x + distance, y: position.y + distance},
            {x: position.x - distance, y: position.y + distance},
            {x: position.x + distance, y: position.y - distance}
        ]
    )
    const distanceSquaredMax = distance * distance;
    const bodies = Matter.Query.region(allBodies, forceBounds)
    for (var i = 0; i < bodies.length; i++) {
        const body = bodies[i];

        const distanceSquared = Matter.Vector.magnitudeSquared(Matter.Vector.sub(body.position, position));
        if (distanceSquared < distanceSquaredMax
            // Matter.Bounds.contains(body.bounds, position)
            /*&& Detector.canCollide(body.collisionFilter, mouseConstraint.collisionFilter)*/) {
            for (var j = body.parts.length > 1 ? 1 : 0; j < body.parts.length; j++) {
                var part = body.parts[j];
                // if (Matter.Vertices.contains(part.vertices, position)) {
                // apply force
                // console.log('apply force', body, position, force)
                const power = 1 / Math.pow((1 + distanceSquared / distanceSquaredMax), 1.5);
                Matter.Body.applyForce(body, position, Matter.Vector.mult(force, power))
                // body.constraint.pointA = position;
                // constraint.bodyB = mouseConstraint.body = body;
                // constraint.pointB = {x: position.x - body.position.x, y: position.y - body.position.y};
                // constraint.angleB = body.angle;

                Matter.Sleeping.set(body, false);
                // Matter.Events.trigger(mouseConstraint, 'startdrag', {mouse: mouse, body: body});

                // break;
                // }
            }
        }
    }

}