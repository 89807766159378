import * as p5 from 'p5';
import {generateTraits} from "./traits";
import {createPhysics} from "./physics/physics";
import {palettes} from "./palettes";
import * as Matter from "matter-js";
import {Body, Composite, Mouse, Vector} from "matter-js";
import {loadLoadToWorld, storeWorldLocal} from "./resurrect/local";
import {createDisplayOptions, createGui, DisplayOptions, shapeTypesValues} from "./display/display-options";
import {createPrng, generateTxHash, isPWPreview, setProperties} from "./pw";
import {GUI} from "lil-gui";
import {SComposite} from "./state/types";
import {AutoTimer} from "./auto/auto-timer";
import {Actions, getKeyboard} from "./keyboard";
import {Bezier, Point} from "bezier-js";
import {Scribble} from "./p5.scribble";
import {failedToFindUnoccupiedCircleError, getCircleCollision} from "./physics/get-circle-collision";
//
// let devMode = true;
// if (devMode) {
//   /**
//    * Enable new hashes on click during development.
//    */
//   const freshHash = () => {
//     window.location.href = '?hash=' + generateTxHash()
//   }
//   window.addEventListener('touchend', function () {
//     freshHash()
//   });
//   document.addEventListener('keyup', (e) => {
//     if (e.key === ' ') {
//       freshHash()
//     }
//   })
// }


let s = (sk: p5) => {
  let prng = createPrng();
  const {traits, attributes} = generateTraits(prng);
  setProperties(attributes, traits);

  let physics: any;
  let gui: GUI;
  const displayOptions: DisplayOptions = createDisplayOptions();

  const keyboard = getKeyboard()
  const scribble = new Scribble(sk);  // instance mode
  scribble.bowing = 0.1
  scribble.roughness = 2.25
  scribble.maxOffset = 0.5

  const getDimensions = () => {
    let desiredHeight = sk.windowHeight
    let desiredWidth = sk.windowWidth;
    // if (desiredWidth > sk.windowWidth) {
    //     desiredWidth = sk.windowWidth;
    //     desiredHeight = sk.windowWidth;
    // }
    // return [canvasDimensions.width, canvasDimensions.height]
    return [desiredWidth, desiredHeight]
  }

  let p5MouseToMatterMouse = {
    position: Matter.Vector.create(0, 0),
    button: -1,
    viewDimensions: {width: getDimensions()[0], height: getDimensions()[1]},
    wheelDelta: 0,
    scale: Matter.Vector.create(1, 1),
    offset: Matter.Vector.create(0, 0),
    absolute: Matter.Vector.create(0, 0),
    sourceEvents: {
      // @ts-ignore
      mousemove: null,
      // @ts-ignore
      mousedown: null,
      // @ts-ignore
      mouseup: null,
      // @ts-ignore
      mousewheel: null
    }
  };

  const fillColors: Record<number, p5.Color> = {};
  let prevMouseBody: { targetComposite: Composite | null, body: Body | null } = {targetComposite: null, body: null};
  let lastMouseBody: { targetComposite: SComposite | null, body: Body | null } = {targetComposite: null, body: null};

  let autoTimer: AutoTimer = new AutoTimer(0, 0, "SWIRL_FORCE")
  let previousMouseIsPressed = false;
  const newCreatePhysics = () => {
    physics?.clear()
    physics = createPhysics({p5: sk, prng, p5MouseToMatterMouse, dimensions: getDimensions()});
    physics.setupWorldMouseAndView(true)
    return physics;
  }
  const updateGui = () => {
    gui.controllersRecursive().forEach(c => {
      // console.log('update gui', c.property, '==', c.object[c.property])
      // if (c.property === 'paused') {
      //   console.log('update gui', c.property, '==', c.object[c.property])
      // }
      c.updateDisplay()
    })
  }
  const freezeToggleAction = () => {
    displayOptions.freezeToggle = !displayOptions.freezeToggle
    if (lastMouseBody.targetComposite) {
      // make it static
      physics.toggleStatic(lastMouseBody.targetComposite)
      insertHistoryEntry()
      deselectComposite()
    } else {
      console.log('displayOptions.freezeToggle', displayOptions.freezeToggle)
      physics.allComposites().forEach((c: Composite) => physics.toggleStatic(c, displayOptions.freezeToggle))
    }
  }
  sk.setup = () => {
    const dimensions = getDimensions();
    sk.createCanvas(dimensions[0], dimensions[1])
    console.log('resized', dimensions)
    sk.colorMode(sk.HSL)

    physics = newCreatePhysics()
    physics.debugInfo.writeToDom()

    gui = createGui(displayOptions, (name, value) => {
      if (name === 'zoom') {
        physics.scaleCanvas(value)
      } else if (name === 'Auto' && value) {
        // physics.clear()
        // physics.setupWorldMouseAndView()
        autoTimer = AutoTimer.createRandom(sk, prng)
        console.log('new auto timer', autoTimer.action, 'for', autoTimer.duration)
      } else if (name === 'paused') {
        if (value) {
          pauseAction()
        } else {
          resumeAction()
        }
      } else if (name === 'activeColor') {
        // change the color of the current selected shape :)
        const srender = lastMouseBody.targetComposite?.srender
        if (typeof srender === 'object') {
          srender.color = sk.color(displayOptions.activeColor)

        }
      } else if (name === 'scribbleMode') {
        const srender = lastMouseBody.targetComposite?.srender
        if (typeof srender === 'object') {
          srender.scribbleMode = !srender.scribbleMode

        }
      } else if (name === 'freezeSelected') {
        freezeToggleAction()
      }
    })

    const setupKeyboardActions = () => {
      keyboard.registerHandler(Actions.NewObject, () => {
        const {x, y} = screenToWorld(sk.mouseX, sk.mouseY)
        let out: any[];
        if (displayOptions.shapeType === 'circle') {
          out = physics.addRandomChain({x, y, size: displayOptions.size})
        } else if (displayOptions.shapeType === 'line') {
          //todo
          out = physics.addLineChain({x, y, size: displayOptions.size})
        } else {
          throw new Error('it didn\'t work')
        }
        //todo setup all the srender stuff based on current settings
        console.log('craeted shape')
        insertHistoryEntry()
        deselectComposite()
        lastMouseBody.targetComposite = out.length ? out[0] : null
        if (lastMouseBody.targetComposite) {
          lastMouseBody.targetComposite.srender = {
            ...(lastMouseBody.targetComposite.srender || {}),
            color: sk.color(displayOptions.activeColor),
            strokeOnly: displayOptions.outlineOnly,
            outlineWeight: displayOptions.outlineWeight,
            scribbleMode: displayOptions.scribbleMode
          }
        }
      })
      keyboard.registerHandler(Actions.SaveScreenshot, () => {
        let queryHash = new URLSearchParams(window.location.search).get('hash')
        const hash = queryHash
        sk.save(`physics-face-${hash}.png`);
      })
      keyboard.registerHandler(Actions.ToggleOutlineMode, () => {
        if (lastMouseBody.targetComposite) {
          // make it static
          if (lastMouseBody.targetComposite.srender) {
            lastMouseBody.targetComposite.srender.strokeOnly = !lastMouseBody.targetComposite.srender.strokeOnly
          }
        } else {
          displayOptions.outlineOnly = !displayOptions.outlineOnly
          updateGui()
        }
      })
      keyboard.registerHandler(Actions.DeleteTargetObject, () => {
        physics.removeSelected(lastMouseBody.targetComposite)
        insertHistoryEntry()
      })

      keyboard.registerHandler(Actions.Undo, () => {
        physics = newCreatePhysics()

        loadLoadToWorld(physics.engine, displayOptions["Scene Name"], displayOptions.undoLookback, () => {
          physics.setupWorldMouseAndView(true)
          displayOptions.undoLookback++
        })
      })

      keyboard.registerHandler(Actions.FreezeObject, () => {
        freezeToggleAction()
        //todo create a freeze button
      })
      keyboard.registerHandler(Actions.ScribbleMode, () => {
        if (lastMouseBody.targetComposite) {
          // make it static
          physics.toggleStatic(lastMouseBody.targetComposite)
          if (lastMouseBody.targetComposite.srender) {
            lastMouseBody.targetComposite.srender.scribbleMode = !lastMouseBody.targetComposite.srender.scribbleMode
          }
        } else {
          displayOptions.scribbleMode = !displayOptions.scribbleMode
          updateGui()
        }

      })

      // keyboard.registerHandler(Actions.FreezeObject, () => {
      //   if (lastMouseBody.targetComposite) {
      //     // make it static
      //     physics.toggleStatic(lastMouseBody.targetComposite)
      //     insertHistoryEntry()
      //     deselectComposite()
      //   }
      // })
      keyboard.registerHandler(Actions.TogglePause, () => {
        displayOptions.paused = !displayOptions.paused
        if (displayOptions.paused) {
          pauseAction()
        } else {
          resumeAction()
        }

        // it's actually updated in the hanlde chang eof the gui
        updateGui()
      })

      keyboard.registerHandler(Actions.NextShapeType, () => {
        displayOptions.shapeTypeIndex = (displayOptions.shapeTypeIndex + 1) % shapeTypesValues.length
        displayOptions.shapeType = shapeTypesValues[displayOptions.shapeTypeIndex] || shapeTypesValues[0]
        updateGui()
      })
      keyboard.registerHandler(Actions.SwirlForce, () => {

        physics.applySwirlForce({applyToComposites: lastMouseBody.targetComposite ? [lastMouseBody.targetComposite] : undefined})
      })
      keyboard.registerHandler(Actions.CompressionForce, () => {
        physics.applyCompressionForce({applyToComposites: lastMouseBody.targetComposite ? [lastMouseBody.targetComposite] : undefined})
      })
      keyboard.registerHandler(Actions.DecompressionForce, () => {
        physics.applyCompressionForce(
            {
              inverse: true,
              applyToComposites: lastMouseBody.targetComposite ? [lastMouseBody.targetComposite] : undefined
            })
      })
      keyboard.registerHandler(Actions.NextColor, () => {
        displayOptions.nextColor()
        if (lastMouseBody.targetComposite) {
          if (lastMouseBody.targetComposite.srender) {
            lastMouseBody.targetComposite.srender.color = sk.color(displayOptions.activeColor)
          }
        }
        updateGui()
      })
    }
    setupKeyboardActions()
  }

  const pauseAction = () => {
    physics.stop()
  }

  const resumeAction = () => {
    physics.resume()
  }


  const screenToWorld = (x: number, y: number) => {
    // const xs = worldDimensions.width / sk.width, ys = worldDimensions.height / sk.height
    // x += sk.width / xs
    // y += sk.height / ys
    return {x: x * displayOptions.zoom, y: y * displayOptions.zoom}
  }

  const insertHistoryEntry = async () => {
    storeWorldLocal(physics.engine, displayOptions["Scene Name"], {
      palette: palettes[displayOptions.palette_name]
    }).then(() => {
      displayOptions.undoLookback = 0
      console.log('inserted history entry')
    })

  }

  const deselectComposite = () => {
    lastMouseBody = {targetComposite: null, body: null}
  }
  const getOrCreateIdColor = (entity: SComposite) => {
    let {color, strokeOnly, outlineWeight, compositeType, scribbleMode} = entity.srender || {}
    const applyDrawOptions = () => {
      if (strokeOnly || compositeType === 'chain-line') {
        sk.stroke(color);
        sk.strokeWeight(outlineWeight);
        sk.noFill()
      } else {
        sk.noStroke()
        sk.fill(color)
      }
    }
    if (color) {
      return {...entity.srender, applyDrawOptions}
    }
    color = sk.color(displayOptions.activeColor);
    strokeOnly = displayOptions.outlineOnly
    outlineWeight = displayOptions.outlineWeight
    scribbleMode = displayOptions.scribbleMode
    if (!entity.srender) {
      entity.srender = {color, strokeOnly, outlineWeight, compositeType}
    } else {
      entity.srender.color = color
      entity.srender.strokeOnly = strokeOnly
      entity.srender.outlineWeight = outlineWeight
      entity.srender.compositeType = compositeType
      entity.srender.scribbleMode = scribbleMode
    }

    return {...entity.srender, applyDrawOptions}
  }
  sk.draw = () => {
    // we are now just fullscreen
    // let xs = worldDimensions.width / sk.width, ys = worldDimensions.height / sk.height
    // xs = -sk.width / xs;
    // ys = -sk.height / ys;
    keyboard.update()

    // physics.setMouseOffset(p5MouseToMatterMouse.viewDimensions, p5MouseToMatterMouse.offset)

    const palette = palettes[displayOptions.palette_name] || palettes['red_black_white']


    const autoTimerLogic = () => {
      if (autoTimer.action === 'CREATE_SHAPE') {
        if (autoTimer.isTriggeredOnce(sk.millis())) {
          console.log('auto timer triggered, CREATE_SHAPE')
          displayOptions.activeColor = prng.randomList(palette.bg.concat(palette.mids).concat(palette.highlights))
          const x = sk.random(sk.width)
          const y = sk.random(sk.height)
          let size = sk.random(30, 80)
          let success = false;
          for (let i = 0; i < 50; i++) {
            try {
              const composites = physics.addRandomChain({x, y, size, nonOverlapping: true})
              if (composites.length) {
                composites.forEach((c: SComposite) => {
                  c.srender = {
                    color: sk.color(displayOptions.activeColor),
                    outlineWeight: displayOptions.outlineWeight,
                    strokeOnly: displayOptions.outlineOnly,
                    scribbleMode: displayOptions.scribbleMode
                  }
                })
              }
              success = true;
              break;
            } catch (e) {
              if (e?.message === failedToFindUnoccupiedCircleError) {
                break;
              }
            }
            size = sk.random(5, size * .9)
          }
          if (!success) {
            // displayOptions["Auto"] = false
            // autoTimer = new AutoTimer(0, 0, "SWIRL_FORCE")
            console.warn('failed to find an unoccupied location for CREATE SHAPE')
          }


        }
      } else if (autoTimer.action === 'INLAID_SHAPE') {
        if (autoTimer.isTriggeredOnce(sk.millis())) {
          console.log('auto timer triggered, INLAID_SHAPE')
          const allColors = palette.bg.concat(palette.mids).concat(palette.highlights);
          const color1 = prng.randomList(allColors);
          const color2 = prng.randomList(allColors.filter(c => c !== color1));
          const randomPaletteColor = () => {
            prng.randomList(palette.bg.concat(palette.mids).concat(palette.highlights))
          }
          displayOptions.activeColor = color2;
          const x = sk.random(sk.width)
          const y = sk.random(sk.height)
          let size = sk.random(30, 80)

          let success = false;
          for (let i = 0; i < 50; i++) {
            try {
              let composites: any[];
              composites = physics.addRandomChain({x, y, size, inlaid: true, nonOverlapping: true})
              composites.forEach((c: SComposite, i: number) => {
                if (i % 2 === 0) {
                  c.srender = {
                    color: sk.color(color1),
                    outlineWeight: displayOptions.outlineWeight,
                    strokeOnly: displayOptions.outlineOnly,
                    scribbleMode: displayOptions.scribbleMode
                  }
                } else {
                  c.srender = {
                    color: sk.color(color2),
                    outlineWeight: displayOptions.outlineWeight,
                    strokeOnly: displayOptions.outlineOnly,
                    scribbleMode: displayOptions.scribbleMode
                  }
                }
              })
              success = true
              break;
            } catch (e) {
              if (e?.message === failedToFindUnoccupiedCircleError) {
                break;
              }
            }
            size = sk.random(5, size * .9)
          }
          if (!success) {
            console.warn('failed to find an unoccupied location for INLAID SHAPE')
            // displayOptions["Auto"] = false
            // autoTimer = new AutoTimer(0, 0, "SWIRL_FORCE")
          }
        }
      } else if (autoTimer.action === 'SWIRL_FORCE') {
        physics.applySwirlForce({scaleForce: 0.1})
      } else if (autoTimer.action === 'COMPRESS_FORCE') {
        physics.applyCompressionForce()
      } else if (autoTimer.action === 'UNCOMPRESS_FORCE') {
        physics.applyCompressionForce(true)
      } else if (autoTimer.action === 'SWIRL_ONE') {
        let bodies = autoTimer.selectedBodies
        if (!bodies) {
          const numDragBodies = prng.randomInt(1, 3)
          bodies = []
          const foundbodies = physics.randomBody({count: numDragBodies})
          if (!foundbodies.length) {
            //no body, restart timer
            autoTimer = AutoTimer.createRandom(sk, prng)
            return;
          }
          const createCurve = (body: Body) => {
            const startCurve = body.position
            // const endCurve = Vector.create(prng.random(-sk.width, sk.width * 2), prng.random(-sk.height, sk.height * 2))
            const endCurve = Vector.create(prng.random(0, sk.width), prng.random(0, sk.height))
            const ptr = Vector.sub(endCurve, startCurve)
            const dist = Vector.magnitude(endCurve)
            const controlPointDist1 = sk.random(0, dist / 2)
            const controlPointDist2 = sk.random(0, dist / 2) + dist / 2
            const angleChange = sk.HALF_PI
            const rotateStart = sk.random(-angleChange, angleChange)
            const rotateEnd = sk.random(-angleChange, angleChange)
            const n = Vector.normalise(ptr)
            const controlPoint1 = Vector.add(Vector.mult(Vector.rotate(n, rotateStart), controlPointDist1), startCurve)
            const controlPoint2 = Vector.add(Vector.mult(Vector.rotate(n, rotateEnd), controlPointDist2), startCurve)
            return new Bezier([startCurve, controlPoint1, controlPoint2, endCurve])
          }
          for (let i = 0; i < foundbodies.length; i++) {
            bodies.push({
              body: foundbodies[i], curve: createCurve(foundbodies[i])
            })
          }

          autoTimer.selectedBodies = bodies
        }
        const time = sk.millis()
        autoTimer.selectedBodies.forEach(({body, curve}) => {
          const t = (time - autoTimer.startTime) / (autoTimer.endTime - autoTimer.startTime)
          const toPosition = curve.get(t)
          physics.applyDraggingForce({scaleForce: 0.04, body, toPosition})
        })

      }
      if (autoTimer.isComplete(sk.millis())) {
        storeWorldLocal(physics.engine, displayOptions["Scene Name"], {
          palette: palettes[displayOptions.palette_name]
        }).then(() => {
        })
        autoTimer = AutoTimer.createRandom(sk, prng)
        console.log(autoTimer.action, 'auto timer complete. new timer', (autoTimer.duration / 1000).toFixed(2))
      }
      //randomly add a new body with random color
      //randomly apply forces
    }
    if (displayOptions['Auto']) {
      autoTimerLogic()

    }


    const bg = sk.color(traits.bgHue, traits.bgSaturation, traits.bgLightness);
    sk.background(palette.bg[0])

    type CompositePosition = {
      id: number;
      positions: ({ id: number; position: Matter.Vector })[];
      composite: Composite
    }
    const positionsGroups: CompositePosition[] = physics.allPositions()

    sk.push()
    // this should actually be the ratio of the canvas to the physics world
    // sk.translate(xs, ys)

    // const scaleFactor = sk.map(displayOptions.zoom, 0, 1, 1, 0.5)
    sk.scale(1 / displayOptions.zoom)
    physics.setMouseScale({x: displayOptions.zoom, y: displayOptions.zoom})


    positionsGroups.sort((a, b) => {
      return a.id - b.id
    })

    const mouseConstraintBody = physics.mouseConstraintBody()
    const {targetComposite} = mouseConstraintBody
    // console.log({
    //   mouseConstraintBody: mouseConstraintBody?.targetComposite,
    //   prevMouseBody: prevMouseBody?.targetComposite
    // })
    if (targetComposite) {
      lastMouseBody = mouseConstraintBody;
    }

    if (!targetComposite && prevMouseBody?.targetComposite) {
      // stopped dragging
      insertHistoryEntry()
    }
    if (!sk.mouseIsPressed && previousMouseIsPressed && !targetComposite && !prevMouseBody?.targetComposite) {
      deselectComposite()
    }

    positionsGroups.forEach(({id, positions, composite}: CompositePosition) => {
      let {
        color,
        applyDrawOptions,
        strokeOnly,
        compositeType,
        outlineWeight,
        scribbleMode
      } = getOrCreateIdColor(composite)
      sk.beginShape()
      sk.randomSeed(id)

      const strokeSelected = lastMouseBody.targetComposite?.id === id;


      // if (displayOptions.onion) {
      //   const onionColor = sk.color(color.toString('rgb'))
      //   onionColor.setAlpha(.5);
      //   color = onionColor;
      // }
      applyDrawOptions()
      // if (displayOptions.outlineOnly) {
      //   sk.stroke(color)
      //   sk.strokeWeight(displayOptions.outlineWeight)
      //   sk.noFill()
      // } else {
      //   sk.fill(color)
      // }

      const experimentalDraw = () => {
        // sk.translate(sk.width / 2, 0)
        const pointPairs: [number, number][] = []


        positions.reverse()
        const isCircle = !compositeType || compositeType === 'chain-circle'
        if (isCircle) {
          // pointPairs.push([positions[positions.length - 2].position.x, positions[positions.length - 2].position.y])
          pointPairs.push([positions[positions.length - 1].position.x, positions[positions.length - 1].position.y])
        } else {
          pointPairs.push([positions[0].position.x, positions[0].position.y])
        }
        positions.forEach(({position: {x, y}, id}) => {
          pointPairs.push([x, y])
        })
        if (isCircle) {
          pointPairs.push([positions[0].position.x, positions[0].position.y])
          pointPairs.push([positions[1].position.x, positions[1].position.y])
        } else {
          pointPairs.push([positions[positions.length - 1].position.x, positions[positions.length - 1].position.y])
        }

        for (let i = 0; i < pointPairs.length - 4; i += 2) {
          const flatPoints = pointPairs.slice(i, i + 4).flat()
          const b = new Bezier(flatPoints)

          const drawLut = (step: Point, i: number, arr: Point[]) => {
            if (i === arr.length - 1) {
              return;
            }
            scribble.scribbleLine(arr[i].x, arr[i].y, arr[i + 1].x, arr[i + 1].y)
            // sk.ellipse(step.x, step.y, 5)

          }
          // const drawScribble = (bezier: Bezier, outline: PolyBezier) => {
          //   // sk.noFill()
          //   const ps = innercurve.points
          //   if (ps.length !== 4) {
          //     return
          //   }
          //   scribble.scribbleCurve(ps[0].x, ps[0].y, ps[3].x, ps[3].y,
          //       ps[2].x, ps[2].y, ps[1].x, ps[1].y,
          //   )
          // }
          const selectCurves = [b]
          // const selectCurves = [innercurve, outtercurce]
          // const selectCurves = outline.curves

          selectCurves.map(c => c.getLUT(5)).flat(2).forEach(drawLut)
          // innercurve.getLUT(5).forEach(drawLut)
          // outtercurce.getLUT(5).forEach(drawLut)

          // outline.curves[1].getLUT(5).forEach(drawLut)
          const steps = b.getLUT(5)
          // steps.forEach(drawLut)
        }
      }

      const drawShape = () => {
        // sk.stroke(color)
        // sk.strokeWeight(2)
        sk.push()
        // sk.translate(sk.width / 2, 0)
        positions.reverse()
        const isCircle = !compositeType || compositeType === 'chain-circle'
        if (isCircle) {
          sk.curveVertex(positions[positions.length - 1].position.x, positions[positions.length - 1].position.y)
        } else {
          sk.curveVertex(positions[0].position.x, positions[0].position.y)
        }
        positions.forEach(({position: {x, y}, id}) => {
          sk.curveVertex(x, y)
        })
        if (isCircle) {
          sk.curveVertex(positions[0].position.x, positions[0].position.y)
          sk.curveVertex(positions[1].position.x, positions[1].position.y)
        } else {
          sk.curveVertex(positions[positions.length - 1].position.x, positions[positions.length - 1].position.y)
        }
        sk.endShape()
        sk.pop()
      }
      if (scribbleMode) {
        sk.stroke(color)
        sk.strokeWeight(outlineWeight)
        experimentalDraw()
      } else {
        drawShape()
      }
      if (strokeSelected) {
        applyDrawOptions()
        sk.noFill()
        sk.strokeWeight(2)
        sk.stroke(sk.color(200, 180, 180, 0.5))
        drawShape()
      }

    })


    if (keyboard.isKeyDown('n')) {
      const color = sk.color(displayOptions.activeColor)
      color.setAlpha(.5)


      if (displayOptions.scribbleMode || displayOptions.outlineOnly || displayOptions.shapeType === 'line') {
        sk.noFill()
        sk.stroke(color)
        sk.strokeWeight(displayOptions.outlineWeight)
      } else {
        sk.noStroke();
        sk.fill(color)
      }
      if (displayOptions.shapeType === 'line') {
        sk.noFill()
        sk.stroke(color)
        sk.strokeWeight(displayOptions.outlineWeight)
        const radius = physics.computeLineWidth({numNodes: displayOptions.size}) * 2
        const p = [sk.mouseX, sk.mouseY, sk.mouseX + radius, sk.mouseY].map(p => p * displayOptions.zoom)
        sk.line(p[0], p[1], p[2], p[3])
      } else {
        if (displayOptions.outlineOnly || displayOptions.scribbleMode) {
          sk.noFill()
          sk.stroke(color)
          sk.strokeWeight(displayOptions.outlineWeight)
        } else {
          sk.noStroke();
          sk.fill(color)
        }
        const radius = physics.computeRadius({numNodes: displayOptions.size})
        const [x, y] = [sk.mouseX * displayOptions.zoom, sk.mouseY * displayOptions.zoom]
        const showCollisions = false;
        if (showCollisions) {
          const body = getCircleCollision(physics.engine, {radius, x, y})
          if (body) {
            sk.push()
            sk.fill('pink')
            sk.stroke('white')
            sk.strokeWeight(2)
            sk.ellipse(body.position.x, body.position.y, 5)
            sk.pop()
          }
        }
        sk.ellipse(x, y, radius * 2)
      }
    }

    const mouse = physics.getMouse()
    const mouseInfo = (mouse: Mouse | typeof p5MouseToMatterMouse) => {
      physics.debugInfo.clearInfo()
      physics.debugInfo.addInfo({title: 'mouse btn', value: mouse.button})
      physics.debugInfo.addInfo({title: 'MX', value: mouse.position.x})
      physics.debugInfo.addInfo({title: 'MY', value: mouse.position.y})
      physics.debugInfo.addInfo({title: 'body', value: targetComposite?.id})
      keyboard.getKeysDown().forEach((k, i) => {
        physics.debugInfo.addInfo({title: 'Key-' + i, value: k})
      })
      physics.debugInfo.writeToDom()
    }

    if (displayOptions.cursor) {
      physics.debugInfo.show()
      mouseInfo(mouse)
    } else {
      physics.debugInfo.hide()
    }


    // setPreviewReady()
    // sk.noLoop()
    prevMouseBody = mouseConstraintBody
    previousMouseIsPressed = sk.mouseIsPressed
  }
  sk.windowResized = () => {
    if (!isPWPreview()) {
      const dimensions = getDimensions();
      sk.resizeCanvas(dimensions[0], dimensions[1]);
      // redraw at new dimensions
      sk.loop()
      console.log('resized', dimensions)
      physics.resizeCanvas(dimensions)
      p5MouseToMatterMouse.viewDimensions.width = sk.width
      p5MouseToMatterMouse.viewDimensions.height = sk.height
    }
    //todo

    physics.resizeCanvas(getDimensions())
  }
  sk.keyPressed = (e: { key: string }) => {
    keyboard.onKeyPressed(e.key)
  }
  sk.keyReleased = (e: { key: string }) => {
    keyboard.onKeyReleased(e.key)

  }
  // sk.mouseMoved = (e: MouseEvent) => {
  //   p5MouseToMatterMouse.position.x = e.x
  //   p5MouseToMatterMouse.position.y = e.y
  // }
  // sk.mousePressed = () => {
  //   p5MouseToMatterMouse.button = 0
  // }
  // sk.mouseReleased = () => {
  //   p5MouseToMatterMouse.button = -1
  // }
  sk.mouseWheel = (e: any) => {
    displayOptions.size += -1 * (e.deltaY as number ?? 0) / 100
    displayOptions.size = Math.min(Math.max(3, displayOptions.size), 75)
    updateGui()
    p5MouseToMatterMouse.wheelDelta = e.deltaY
  }
}

export const createSketch = () => {
  const myword = 'pizza'
  console.log(myword, 'pizza')
  return new p5(s, document.getElementById('sketch'));
  // let physics = createPhysics();

}
