import Matter from "matter-js";
import {constants} from "../../constants";
import {applyDragForce, applySwirlForce} from "../swirlforce";
import {inlaidCircleFromNodeSize, inlaidCircleFromRadius} from "./chain-shapes";
import {DebugInfo} from "../../debug-info";
import {computeNumberOfNodesFromRadius, computeRadius} from "../compute";
import {applyCompressionForce} from "../compressionforce";
import {rotate2d} from "../../math/vector";
import {findUnoccupiedCircle, getCircleCollision} from "../get-circle-collision";

//the physics world size
export const worldDimensions = Object.freeze({width: 700 * 2, height: 700 * 2})

export const canvasDimensions = Object.freeze({
    width: worldDimensions.width / 2,
    height: worldDimensions.height / 2,
});

//size of the debug view
// const debugViewDimensions = Object.freeze({
//     width: canvasDimensions.width,
//     height: canvasDimensions.height,
// });
// const debugViewDimensions = Object.freeze({
//     width: worldDimensions.width / 4,
//     height: worldDimensions.height / 4,
// });


const delta = 16.67;
export const createPhysicsChains = ({liveMode = false, prng, p5, p5MouseToMatterMouse, dimensions}) => {
    const debugInfo = new DebugInfo();

    var Engine = Matter.Engine,
        Render = Matter.Render,
        Runner = Matter.Runner,
        Body = Matter.Body,
        Composite = Matter.Composite,
        Composites = Matter.Composites,
        Constraint = Matter.Constraint,
        MouseConstraint = Matter.MouseConstraint,
        Mouse = Matter.Mouse,
        Bodies = Matter.Bodies;

    let dimensionsInternal = dimensions

    // create engine
    var engine = Engine.create()
    engine.enableSleeping = true

    engine.world.gravity.y = 0;
    // create renderer
    const render = null
    // var render = Render.create({
    //     element: document.getElementById("debug-view"),
    //     engine: engine,
    //     options: {
    //         // ...debugViewDimensions,
    //         width: dimensionsInternal[0],
    //         height: dimensionsInternal[1],
    //         showAngleIndicator: false,
    //         showCollisions: false,
    //         showVelocity: false, // showBounds: true,
    //         // showVelocity:true
    //         // showPerformance: true,
    //         enabled: true,
    //     },
    // });
    render && Render.run(render);

    // create runner
    var runner = Runner.create();
    liveMode && Runner.run(runner, engine);

    // add bodies
    var group = Body.nextGroup(true);

    let bodiesToInsertIntoWorld = [];

    const stiffness = 0.9;
    const frictionAir = 0.9;
    const widthLink = 10;


    const createChain = ({
                             x: xx,
                             y: yy,
                             radius,
                             numLinks,
                             widthLink,
                             linkGap,
                             insertInto,
                             nonOverlapping
                         }) => {

        linkGap = linkGap || radius;
        const ropeA = Composite.create();
        const N = Math.floor(numLinks);
        const radiusComputed = computeRadius({linkGap, widthLink, N, p5})

        let xfound = xx;
        let yfound = yy;
        if (nonOverlapping) {
            [xfound, yfound] = findUnoccupiedCircle({engine, x: xx, y: yy, radius: radiusComputed, p5, prng})
        }
        xx = xfound;
        yy = yfound

        for (let i = 0; i < N; i++) {
            const colGap = radiusComputed;
            const rowGap = radiusComputed;
            const a = ((i + 1) / N) * constants.TWO_PI;
            const x = Math.cos(a) * colGap + xx,
                y = Math.sin(a) * rowGap + yy;

            const body = Bodies.circle(x, y, widthLink * 0.5, {
                // collisionFilter: {group: group},
                frictionAir: 0.6,
                angle: a + Math.PI / 2,
            });
            Composite.add(ropeA, body);
        }

        // var ropeA = Composites.stack(100, 50, 8, 1, 10, 10, function (x, y) {
        //     return Bodies.rectangle(x, y, 50, 20, {collisionFilter: {group: group}, frictionAir: 0.9});
        // });

        //length here is the length between chain links
        chain(ropeA, 0.5, 0, -0.5, 0, {
            stiffness,
            length: linkGap,
            render: {type: "line"},
        });
        // //spring attachment point
        // let dist = p5.dist(
        //     rotate2d({x: 0, y: widthLink * 0.5}, 0).x,
        //     rotate2d(
        //         {
        //             x: 0,
        //             y: widthLink * 0.5,
        //         },
        //         0,
        //     ).y,
        //     0,
        //     0,
        // );
        // console.log({dist, linkGap})
        Composite.add(
            ropeA,
            Constraint.create({
                bodyB: ropeA.bodies[0],
                bodyA: ropeA.bodies[ropeA.bodies.length - 1],
                pointA: rotate2d({x: 0, y: widthLink * 0.5}, p5.QUARTER_PI / 2),
                pointB: rotate2d({x: 0, y: -widthLink * 0.5}, p5.QUARTER_PI),
                stiffness,
            }),
        );
        insertInto.push(ropeA);
        return ropeA;
    };

    const createLineChain = ({
                                 x: xx,
                                 y: yy,
                                 numLinks,
                                 widthLink,
                                 linkGap,
                                 insertInto
                             }) => {

        linkGap = linkGap || radius;
        var ropeA = Composites.stack(xx, yy, numLinks, 1, linkGap * 2, 0, function (x, y) {
            return Bodies.circle(x, y, widthLink, {
                frictionAir,
            });
        });

        Composites.chain(ropeA, 0.5, 0, -0.5, 0, {stiffness, length: undefined, render: {type: 'line'}});
        ropeA.srender = {
            compositeType: "chain-line"
        }
        insertInto.push(ropeA);
        return ropeA;
    };

    const createChainFromRadius = ({
                                       x: xx,
                                       y: yy,
                                       circleRadius,
                                       radius,
                                       widthLink,
                                       linkGap, insertTo
                                   }) => {
        linkGap = linkGap || radius;
        const ropeA = Composite.create();
        const N = computeNumberOfNodesFromRadius({radius: circleRadius, widthLink, linkGap, p5})
        // const N = numLinks;
        const radiusComputed = computeRadius({linkGap, widthLink, N, p5})


        for (let i = 0; i < N; i++) {
            const colGap = radiusComputed;
            const rowGap = radiusComputed;
            const a = ((i + 1) / N) * constants.TWO_PI;
            const x = Math.cos(a) * colGap + xx,
                y = Math.sin(a) * rowGap + yy;

            const body = Bodies.circle(x, y, widthLink * 0.5, {
                collisionFilter: {group: group}, frictionAir,
                angle: a + Math.PI / 2,
            });
            Composite.add(ropeA, body);
        }

        // var ropeA = Composites.stack(100, 50, 8, 1, 10, 10, function (x, y) {
        //     return Bodies.rectangle(x, y, 50, 20, {collisionFilter: {group: group}, frictionAir: 0.9});
        // });

        //length here is the length between chain links
        chain(ropeA, 0.5, 0, -0.5, 0, {
            stiffness,
            length: linkGap,
            render: {type: "line"},
        });
        // //spring attachment point
        let dist = p5.dist(
            rotate2d({x: 0, y: widthLink * 0.5}, 0).x,
            rotate2d(
                {
                    x: 0,
                    y: widthLink * 0.5,
                },
                0,
            ).y,
            0,
            0,
        );
        // console.log({dist, linkGap})
        Composite.add(
            ropeA,
            Constraint.create({
                bodyB: ropeA.bodies[0],
                bodyA: ropeA.bodies[ropeA.bodies.length - 1],
                pointA: rotate2d({x: 0, y: widthLink * 0.5}, p5.QUARTER_PI / 2),
                pointB: rotate2d({x: 0, y: -widthLink * 0.5}, p5.QUARTER_PI),
                stiffness,
            }),
        );
        insertTo.push(ropeA);
        return ropeA;
    };

    // node size and also helps spread shapes apart
    const linkSize = 20;
    // distance between nodes
    const radius = 10;

    const GenShapeOptions = {
        EYE: 0,
        CONTAINED_CIRCLE: 1,
        RANDOM_PLACEMENT: 2,
        MOUTH: 3,
        LINE: 4,
    };
    const generateTypes = [GenShapeOptions.EYE, GenShapeOptions.RANDOM_PLACEMENT];
    const generateType = GenShapeOptions.EYE; //prng.randomList(generateTypes)

    const genList = [GenShapeOptions.EYE, GenShapeOptions.MOUTH]
    const generateEyes = ({insertInto}) => {
        const largeNum = prng.randomInt(10, 20);
        const smallNum = prng.randomInt(3, largeNum - 3);
        let x = worldDimensions.width / 4;
        const y = worldDimensions.height / 3;

        // inlaidCircleFromRadius({
        //     x,
        //     y,
        //     radius,
        //     prng,
        //     circleRadius: containerDimensions.width / 4,
        //     smallNumNodes: smallNum,
        //     largeNumNodes: largeNum,
        //     linkSize,
        //     createChain, p5,
        // });
        // return;

        const {numCircles, computedRadius} = inlaidCircleFromNodeSize({
            x: x += worldDimensions.width / 2,
            y,
            radius,
            prng,
            smallNumNodes: smallNum,
            largeNumNodes: largeNum,
            linkSize,
            createChain, p5, insertInto, engine
        });
        debugInfo.addInfo({title: "NumEyeNodes", value: numCircles})
        x =
            -1 * (x - worldDimensions.width / 2) + worldDimensions.width / 2;
        inlaidCircleFromNodeSize({
            x,
            y,
            radius,
            prng,
            smallNumNodes: smallNum,
            largeNumNodes: largeNum,
            linkSize, createChain, p5, insertInto, engine
        });
    }

    const generateFunc = (generateType, insertInto, opts = {}) => {
        if (generateType === GenShapeOptions.EYE) {
            generateEyes({insertInto})
        } else if (generateType === GenShapeOptions.RANDOM_PLACEMENT) {
            //nonOverlapping
            const x = opts.x ?? prng.randomInt(offset, worldDimensions.width - offset);
            const y = opts.y ?? prng.randomInt(offset, worldDimensions.height - offset);
            const numLinks = opts?.size ?? prng.randomInt(3, 20);
            createChain({
                x,
                y,
                radius,
                numLinks,
                widthLink: linkSize,
                linkGap: radius,
                insertInto,
                nonOverlapping: opts.nonOverlapping
            })


        } else if (generateType === GenShapeOptions.CONTAINED_CIRCLE) {
            const x = opts.x ?? prng.randomInt(offset, worldDimensions.width - offset);
            const y = opts.y ?? prng.randomInt(offset, worldDimensions.height - offset);
            inlaidCircleFromNodeSize({
                p5, prng, x, y, linkSize, radius, insertInto, createChain, nonOverlapping: opts.nonOverlapping, engine
            })

        } else if (generateType === GenShapeOptions.MOUTH) {
            const largeNum = prng.randomInt(20, 30);
            const smallNum = prng.randomInt(2, largeNum - 3);
            const {numCircles, computedRadius} = inlaidCircleFromNodeSize({
                x: worldDimensions.width / 2,
                y: 3 * worldDimensions.height / 4,
                radius: radius,
                prng,
                smallNumNodes: smallNum,
                largeNumNodes: largeNum,
                linkSize: linkSize * 2,
                createChain, p5, insertInto, engine
            });
            debugInfo.addInfo({title: "NumEyeNodes", value: numCircles})
        } else if (generateType === GenShapeOptions.LINE) {
            const x = opts.x ?? prng.randomInt(offset, worldDimensions.width - offset);
            const y = opts.y ?? prng.randomInt(offset, worldDimensions.height - offset);
            const numLinks = opts?.size ?? prng.randomInt(3, 20);
            createLineChain({x, y, radius, numLinks, widthLink: linkSize, linkGap: radius, insertInto})


        }
    }

    // for (let genType of genList) {
    //     generateFunc(genType, bodiesToInsertIntoWorld)
    // }

    // generateEyes()


    // createChain({x: 150, y: 200, radius: 3, numLinks: 5, widthLink: linkSize, linkGap: linkSize})

    let container = null
    const createAddContainer = () => {
        container = Composite.create();
        const wallThickness = 10;

        const dm = {
            width: worldDimensions.width,
            height: worldDimensions.height,
        };
        const length = Math.max(dm.width, dm.height) * 4;
        Composite.add(container, [
            //bottom
            Bodies.rectangle(0, dm.height * 2, length, wallThickness, {
                isStatic: true,
            }),
            //top
            Bodies.rectangle(dm.width, -dm.height, length, wallThickness, {
                isStatic: true,
            }),
            //left
            Bodies.rectangle(-dm.width, dm.height / 2, wallThickness, length, {
                isStatic: true,
            }),
            //right
            Bodies.rectangle(dm.width * 2, dm.width, wallThickness, length, {
                isStatic: true,
            }),
        ]);
        bodiesToInsertIntoWorld.push(container);
        Composite.add(engine.world, bodiesToInsertIntoWorld);
    }

    // createAddContainer()

    let mouseConstraint = null;

    const setupWorldMouseAndView = (resetMouse = false) => {
        if (resetMouse) {
            //remove all previous events
            // if (mouseConstraint.events) {
            //     Object.entries(mouseConstraint.events).forEach(([name, callbacks]) => {
            //         callbacks.forEach(cb => {
            //             Matter.Events.off(mouseConstraint, name, cb)
            //         })
            //     })
            // }
            mouseConstraint = null;
        }


        // add mouse control
        // const sketchElement = document.getElementById("defaultCanvas0");
        if (!mouseConstraint) {

            // if (mouse) {
            //     Mouse.setElement(mouse, null)
            // }

            // const mouse = p5MouseToMatterMouse;//
            let mouse;
            if (engine.mouse) {
                mouse = engine.mouse
            } else {
                engine.mouse = Mouse.create(document.body)
                mouse = engine.mouse
            }
            // const  mouse = engine.mouse || Mouse.create(document.body)
            // p5.canvas.addEventListener('mousemove', (e) => {
            //     console.log("mouse move", e)
            // });
            mouseConstraint = MouseConstraint.create(engine, {
                mouse: mouse,
                constraint: {
                    stiffness: 1.0,
                    // render: {
                    //     visible: false,
                    // },
                },
            });

            Composite.add(engine.world, mouseConstraint);
            const mouseConstraint1 = Composite.allConstraints(engine.world)[0]
            console.log('mouseConstraint', mouseConstraint1.id)

            // keep the mouse in sync with rendering
            if (render) render.mouse = mouse;

            // Matter.Events.on(mouseConstraint, "mouseup", function (e) {
            //     console.log("mouseup", e)
            // });
            // Matter.Events.on(mouseConstraint, "beforeUpdate", function (e) {
            //     console.log("mc beforeUpadate", e)
            // });
        }

        // fit the render viewport to the scene
        let xs = worldDimensions.width / dimensionsInternal[0], ys = worldDimensions.height / dimensionsInternal[1]
        render && Render.lookAt(render, {
            // min: {
            //     x: worldDimensions.width / 2 - worldDimensions.width / xs / 2,
            //     y: worldDimensions.height / 2 - worldDimensions.height / ys / 2
            // },
            // max: {
            //     x: worldDimensions.width / 2 + worldDimensions.width / xs / 2,
            //     y: worldDimensions.height / 2 + worldDimensions.height / ys / 2
            // },
            min: {
                x: 0,
                y: 0,
            },
            max: {
                x: dimensionsInternal[0],
                y: dimensionsInternal[1]
            },
        });
    }


    // Mouse.setScale(mouse, {
    //     x: (render.bounds.max.x - render.bounds.min.x) / render.canvas.width,
    //     y: (render.bounds.max.y - render.bounds.min.y) / render.canvas.height
    // });
    //
    // Mouse.setOffset(mouse, Matter.Vector.add(render.bounds.min, p5MouseToMatterMouse.offset));

    //mouse has a position, and button == 0 or undefined for clicked or not clicked
    // var mouse2 = p5MouseToMatterMouse,
    //     mouseConstraint2 = MouseConstraint.create(engine, {
    //         mouse: mouse2,
    //         constraint: {
    //             stiffness,
    //             render: {
    //                 visible: true,
    //             },
    //         },
    //     });
    // Composite.add(world, mouseConstraint2);

    let force = {
        position: {
            x: 300,
            y: 400,
        },
        force: {
            x: 0.1,
            y: 0.1,
        },
        distance: 400,
    };

    const applySwirlForceHere = ({applyToComposites, scaleForce, body} = {}) => {
        const v = Matter.Vector.create(
            Math.cos(engine.timing.timestamp * 0.0001),
            Math.sin(engine.timing.timestamp * 0.0001),
        );
        force = {
            position: {
                x:
                    dimensionsInternal[0] / 2 +
                    (v.x * dimensionsInternal[0]) / 2,
                y:
                    dimensionsInternal[1] / 2 +
                    (v.y * dimensionsInternal[1]) / 2,
            },
            distance: dimensionsInternal[0] * 0.75,
            force: Matter.Vector.mult(v, -1 * (1 / delta / (200) * (scaleForce || 1))),
            applyToComposites, body
        };

        applySwirlForce(engine, engine.world, force);
    }

    const applyDraggingForce = ({body, toPosition, scaleForce}) => {
        const v = Matter.Vector.sub(toPosition, body.position)

        force = {
            position: body.position,
            force: Matter.Vector.mult(v, -1 * (1 / delta / (200) * (scaleForce || 1))),
            body
        };

        applyDragForce(engine, engine.world, force);
    }
    const applyCompressionForceHere = ({inverse = false, applyToComposites, scaleForce} = {}) => {

        force = {
            position: {
                x:
                    dimensionsInternal[0] / 2,
                y:
                    dimensionsInternal[1] / 2,
            },
            magnitude: 1 / delta / 90000 * (scaleForce || 1),
            inverse,
            applyToComposites
        };

        applyCompressionForce(engine, engine.world, force);
    }
    // Matter.Events.on(engine, "beforeUpdate", function () {
    //     if (p5.keyIsPressed && p5.key === "f") {
    //         applySwirlForceHere()
    //     } else if (p5.keyIsPressed && p5.key === "c") {
    //         applyCompressionForceHere()
    //     } else if (p5.keyIsPressed && p5.key === "r") {
    //         applyCompressionForceHere(true)
    //     }
    // });

    // Matter.Events.on(engine, 'afterUpdate', function () {
    //     const c = render.context
    //     c.beginPath();
    //     c.arc(force.position.x, force.position.y, constants.TWO_PI, 0, 2 * Math.PI);
    //     c.closePath();
    // });

    if (!liveMode) {
        console.log("Running...");
        const steps = 500; //1_000;
        for (let i = 0; i < steps; i++) {
            Engine.update(engine, delta);
        }
        console.log("Done...");
        Composite.remove(engine.world, container);
        var allBodies = Matter.Composite.allBodies(engine.world);
        console.log(allBodies);
    }

    // context for MatterTools.Demo
    return {
        engine: engine, // runner: runner,
        render: render,
        canvas: p5.canvas,
        stop: function () {
            console.log('stopped engine!')
            engine.enabled = false
            Matter.Runner.stop(runner);
        },
        resume: function () {
            console.log('started engine!')
            engine.enabled = true
            Runner.run(runner, engine)
        },

        allPositions: () => {
            let composites = Composite.allComposites(engine.world);
            // composites = composites.filter((c) => c.id !== container.id);
            return composites.map((c) => {
                return {
                    id: c.id,
                    composite: c,
                    positions: Matter.Composite.allBodies(c).map((b) => ({position: b.position, id: b.id})).reverse()
                };
            }).reverse();
            // const containerIds = Matter.Composite.allBodies(container).map(
            //     (b) => b.id,
            // );
            // let out = Matter.Composite.allBodies(engine.world);
            // out = out.filter((b) => !containerIds.includes(b.id));
            // out = out.map((b) => ({
            //     id: b.id, position: b.position
            // }));
            // return out;
        },
        mouseConstraintBody: () => {
            if (mouseConstraint.body) {
                const composites = Composite.allComposites(engine.world, mouseConstraint.body.id, 'composite')
                let composite = null;
                for (let c of composites) {
                    const found = Composite.get(c, mouseConstraint.body.id, 'body')
                    if (found) {
                        composite = c;
                        break;
                    }
                }
                return {body: mouseConstraint.body, targetComposite: composite};
            }
            return {body: null, targetComposite: null};
        },

        removeSelected: (composite) => {
            if (!composite) {
                return
            }

            if (!composite) {
                return
            }
            Composite.remove(engine.world, composite, true)
        },
        addRandomChain: ({x, y, size, inlaid = false, nonOverlapping}) => {
            const bodiesToInsertIntoWorld = []
            if (inlaid) {
                generateFunc(GenShapeOptions.CONTAINED_CIRCLE, bodiesToInsertIntoWorld, {x, y, size, nonOverlapping})
            } else {
                generateFunc(GenShapeOptions.RANDOM_PLACEMENT, bodiesToInsertIntoWorld, {x, y, size, nonOverlapping})
            }
            Composite.add(engine.world, bodiesToInsertIntoWorld);
            return bodiesToInsertIntoWorld;
        },

        addLineChain: ({x, y, size}) => {
            const bodiesToInsertIntoWorld = []
            generateFunc(GenShapeOptions.LINE, bodiesToInsertIntoWorld, {x, y, size})
            Composite.add(engine.world, bodiesToInsertIntoWorld);
            return bodiesToInsertIntoWorld;
        },


        resizeCanvas: ([w, h]) => {
            console.log("resizeCanvas", w, h);
            dimensionsInternal = [w, h];
            //todo this also needs to propagate down into the render component width height
            // render.canvas.width = w;
            // render.canvas.height = h;
            //todo separate zoom out from resize
            setupWorldMouseAndView()
        },
        scaleCanvas: (zoom) => {
            console.log("scaleCanvas", zoom);
            // dimensionsInternal = [w, h];
            // //todo this also needs to propagate down into the render component width height
            // render.canvas.width = w;
            // render.canvas.height = h;
            // //todo separate zoom out from resize
            // setupWorldMouseAndView()
        },
        setupWorldMouseAndView,

        allBodies: bodiesToInsertIntoWorld,
        debugInfo,

        computeRadius: ({numNodes}) => {
            return computeRadius({linkGap: radius, widthLink: linkSize, N: numNodes, p5})
        },
        computeLineWidth: ({numNodes}) => {
            return (radius * (numNodes)) + (linkSize * (numNodes - 1))
        },
        clear: () => {
            Matter.Composite.clear(engine.world, false, true);
            mouseConstraint = null
        },

        applyCompressionForce: applyCompressionForceHere,
        applySwirlForce: applySwirlForceHere,
        applyDraggingForce: applyDraggingForce,
        // setMouseOffset: (dimensions, offset) => {
        //     Mouse.setScale(mouse, {
        //         x: (render.bounds.max.x - render.bounds.min.x) / dimensions.width,
        //         y: (render.bounds.max.y - render.bounds.min.y) / dimensions.height
        //     });
        //
        //     // Mouse.setOffset(mouse, {x: offset.x, y: offset.y})//Matter.Vector.add(render.bounds.min, Matter.Vector.mult(offset, 1));
        //     Mouse.setOffset(mouse, {x: 0, y: 0})//Matter.Vector.add(render.bounds.min, Matter.Vector.mult(offset, 1));
        // }
        setMouseScale: ({x, y}) => {
            engine.mouse && Mouse.setScale(engine.mouse, {x, y});
        },

        toggleStatic: (composite, freezeOn) => {
            const bodies = Composite.allBodies(composite)
            const setStatic = typeof freezeOn === 'boolean' ? freezeOn : !bodies.some(b => b.isStatic)
            bodies.forEach(b => {
                if (setStatic) {
                    Matter.Body.setStatic(b, true);
                } else {
                    Matter.Body.setStatic(b, false);
                }
            })
            console.log("marked composite as static", composite, setStatic)
        },
        getMouse: () => {
            return engine.mouse;
        },
        randomBody: ({count = 1} = {}) => {
            const all = Composite.allBodies(engine.world);
            const outBodies = []
            if (all.length === 0) {
                return [];
            }
            do {
                const body = prng.randomList(all)
                if (!outBodies.includes(body)) {
                    outBodies.push(body)
                }
            } while (outBodies.length < count && outBodies.length < all.length)
            return outBodies;
        },
        allComposites: () => {
            return Composite.allComposites(engine.world)
        }
    };
};

export const chain = function (
    composite,
    xOffsetA,
    yOffsetA,
    xOffsetB,
    yOffsetB,
    options,
) {
    var bodies = composite.bodies;

    for (var i = 1; i < bodies.length; i++) {
        const a = Math.atan2(
            bodies[i].position.y - bodies[i - 1].position.y,
            bodies[i].position.x - bodies[i - 1].position.x,
        );
        var bodyA = bodies[i - 1],
            bodyB = bodies[i],
            bodyAHeight = bodyA.bounds.max.y - bodyA.bounds.min.y,
            bodyAWidth = bodyA.bounds.max.x - bodyA.bounds.min.x,
            bodyBHeight = bodyB.bounds.max.y - bodyB.bounds.min.y,
            bodyBWidth = bodyB.bounds.max.x - bodyB.bounds.min.x;

        const {x: ox, y: oy} = rotate2d(
            {x: bodyAWidth * xOffsetA, y: bodyAHeight * yOffsetA},
            a,
        );
        var defaults = {
            bodyA: bodyA,
            pointA: rotate2d(
                {
                    x: bodyAWidth * xOffsetA,
                    y: bodyAHeight * yOffsetA,
                },
                a,
            ), // pointA: {x: bodyAWidth * xOffsetA, y: bodyAHeight * yOffsetA},
            bodyB: bodyB,
            pointB: rotate2d(
                {
                    x: bodyBWidth * xOffsetB,
                    y: bodyBHeight * yOffsetB,
                },
                a,
            ), // pointB: {x: bodyBWidth * xOffsetB, y: bodyBHeight * yOffsetB}
        };

        var constraint = Matter.Common.extend(defaults, options);

        Matter.Composite.addConstraint(
            composite,
            Matter.Constraint.create(constraint),
        );
    }

    composite.label += " Chain";

    return composite;
};

