import {z} from "zod";

export const stateSchema = z.object({
  name: z.string(),
  date: z.string().datetime()
})
export const sessionIndexSchema = z.object({
  sessions: z.array(z.object({
    name: z.string(),
    statesKeys: z.array(stateSchema)
  }))
})


export type SessionIndex = z.infer<typeof stateSchema>