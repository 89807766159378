import Matter from 'matter-js';
import {getAllBodies} from "./swirlforce";

export const applyCompressionForce = (engine, world, {magnitude, position, inverse, applyToComposites}) => {
    var allBodies = getAllBodies(engine, applyToComposites)

    const bodies = allBodies
    for (var i = 0; i < bodies.length; i++) {
        const body = bodies[i];

        for (var j = body.parts.length > 1 ? 1 : 0; j < body.parts.length; j++) {
            var part = body.parts[j];
            // if (Matter.Vertices.contains(part.vertices, position)) {
            // apply force
            // console.log('apply force', body, position, force)
            const forceDirection = inverse ? Matter.Vector.sub(part.position, position) : Matter.Vector.sub(position, part.position)

            // const power = 1 / Math.pow((1 + distanceSquared / distanceSquaredMax), 2);
            Matter.Body.applyForce(body, position, Matter.Vector.mult(forceDirection, magnitude))
            // body.constraint.pointA = position;
            // constraint.bodyB = mouseConstraint.body = body;
            // constraint.pointB = {x: position.x - body.position.x, y: position.y - body.position.y};
            // constraint.angleB = body.angle;

            Matter.Sleeping.set(body, false);
            // Matter.Events.trigger(mouseConstraint, 'startdrag', {mouse: mouse, body: body});

            // break;
            // }
        }

    }

}