export type Palette = {
  bg: string[],
  highlights: string[],
  mids: string[]
}
export const palettes = {
  'red_black_white': {
    bg: ['#E54B4B'],
    highlights: ['#FFA987', '#F7EBE8'],
    mids: ['#444140', '#1E1E24']
  } satisfies Palette,
  'blues_tans': {
    bg: ['#182825'],
    highlights: ['#016FB9', '#22AED1'],
    mids: ['#6D8EA0', '#AFA98D']
  }satisfies Palette,
  'aquamarine': {
    bg: ['#454B4C'],
    highlights: ['#59A7B3', '#45D0E6'],
    mids: ['#243133', '#597A80']
  }satisfies Palette,
  'pink': {
    bg: ['#70807D'],
    highlights: ['#F962FF', '#61FFE2'],
    mids: ['#FFDB61', '#A879AA']
  }satisfies Palette,
  'black & white': {
    bg: ['#efece1'],
    highlights: ['#454B4C'],
    mids: new Array<string>(),
  }satisfies Palette,
  'Now this': {
    bg: ['#fed4e7'],
    highlights: ['#d8cc34', "#083d77",
      "#da4167"],
    mids: ['#edc9ff',
      '#f2b79f',
      '#e5b769',
      '#2e4057'],
  }satisfies Palette,
  "redwoods": {
    bg: ['#100b00'],
    mids: [
      "#a15e49",
      "#4e3822",
      "#2f1b25", "#100b00",
      "#3b429f",],
    highlights: [
      "#e3d26f", "#ca895f", "#aa7dce"
    ]
  }satisfies Palette,
}


