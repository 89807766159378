import P5 from 'p5'
import {PRNGRand} from "../pw";
import {Body, Vector} from 'matter-js'
import {act} from "react-dom/test-utils";
import {Bezier} from "bezier-js";

const actions = ['INLAID_SHAPE', 'CREATE_SHAPE', 'SWIRL_FORCE', 'COMPRESS_FORCE', "UNCOMPRESS_FORCE", 'SWIRL_ONE', 'REST'] as const

type ActionType = typeof actions[number]

export class AutoTimer {
  startTime: number
  endTime: number
  action: ActionType;
  triggeredOnce: boolean = false;
  selectedBodies: { body: Body; curve: Bezier }[] | null = null

  constructor(startTime: number, endTime: number, action: ActionType) {
    this.startTime = startTime
    this.endTime = endTime
    this.action = action
  }

  get duration() {
    return this.endTime - this.startTime
  }

  isComplete(time: number) {
    return time > this.endTime
  }

  isTriggered(time: number) {
    return this.isTriggeredOnce(time) || time > this.startTime
  }

  isTriggeredOnce(time: number) {
    if (this.triggeredOnce) return false
    if (time > this.startTime) {
      this.triggeredOnce = true
      return true
    }
    return false
  }

  static createRandom(p5: P5, rng: PRNGRand) {
    const start = p5.millis()
    let duration = 0
    let action = rng.randomList(actions.slice(0, -1).filter(t => t !== 'SWIRL_FORCE'))
    // const action = actions[5]
    // action = 'SWIRL_ONE'
    if (action === 'CREATE_SHAPE' || action === 'INLAID_SHAPE') {
      duration = rng.random(1, 100)
    } else if (action === 'REST') {
      duration = rng.random(0, 1)
    } else if (action === 'SWIRL_FORCE') {
      duration = rng.random(0, 1)
    } else if (action === 'COMPRESS_FORCE') {
      duration = rng.random(0, 1000)
    } else if (action === 'UNCOMPRESS_FORCE') {
      duration = rng.random(0, 500)
    } else if (action === 'SWIRL_ONE') {
      duration = rng.random(2000, 5000)
    } else {
      duration = rng.random(0, 100)
    }
    const end = start + duration
    return new AutoTimer(start, end, action)
  }
}