import {computeNumberOfNodesFromRadius, computeRadius} from "../compute";
import {findUnoccupiedCircle} from "../get-circle-collision";

export const inlaidCircleFromNodeSize = ({
                                             p5,
                                             prng,
                                             x,
                                             y,
                                             largeNumNodes,
                                             smallNumNodes,
                                             radius,
                                             linkSize,
                                             createChain, insertInto, engine, nonOverlapping
                                         }) => {
    const largeNum = Math.floor(largeNumNodes) || prng.randomInt(10, 20)
    const smallNum = Math.floor(smallNumNodes) || prng.randomInt(4, largeNum - 3)
    const numCircles = Math.floor((largeNum - smallNum) / 3);
    const computedRadius = computeRadius({linkGap: radius, widthLink: linkSize, N: largeNum, p5})

    let xfound = x, yfound = y;
    if (nonOverlapping) {
        [xfound, yfound] = findUnoccupiedCircle({engine, x, y, radius: computedRadius, p5, prng})
    }
    //first fine the position here

    for (let i = numCircles - 1; i >= 0; i--) {
        const numLinks = p5.map(i, 0, numCircles, smallNum, largeNum)
        createChain({x: xfound, y: yfound, radius, numLinks, widthLink: linkSize, linkGap: radius, insertInto})
    }
    return {computedRadius, x: xfound, y: yfound, largeNumNodes, smallNumNodes, linkGap: radius, numCircles}
}


export const inlaidCircleFromRadius = ({
                                           p5,
                                           prng,
                                           x,
                                           y,
                                           circleRadius,
                                           radius,
                                           linkSize,
                                           createChain
                                       }) => {

    const maxRadius = circleRadius;
    const linkGap = radius * 4;
    const widthLink = linkSize;
    const minRadiusComputed = computeRadius({linkGap, widthLink, N: 3, p5})
    const minRadius = prng.randomInt(minRadiusComputed, maxRadius / 2)
    const numLinksMax = computeNumberOfNodesFromRadius({
        radius: maxRadius,
        linkGap,
        widthLink,
        p5
    })
    const numLinksMin = computeNumberOfNodesFromRadius({
        radius: minRadius,
        linkGap,
        widthLink,
        p5
    })
    const numChangeStep = 4;
    const steps = Math.floor((numLinksMax - numLinksMin) / numChangeStep);
    for (let i = 0; i < steps; i++) {
        const numLinks = p5.map(i, 0, steps, numLinksMin, numLinksMax)
        createChain({x, y, radius, numLinks, widthLink: linkSize, linkGap})
    }
    // console.log({numLinksMax, numLinksMin, steps})

    return;
}