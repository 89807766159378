import GUI from 'lil-gui';
import {palettes} from "../palettes";
import {generate} from "random-words";

export const shapeTypesValues = ['circle', "line"] as const
type ShapeTypes = typeof shapeTypesValues[number];


export type DisplayOptions = {
  palette_name: keyof typeof palettes;
  size: number;
  activeColor: string;
  activeColorIndex: number;
  ['Scene Name']: string
  zoom: number;
  ['Auto']: boolean;
  undoLookback: number;
  paused: boolean;
  outlineOnly: boolean;
  outlineWeight: number;
  shapeType: ShapeTypes;
  shapeTypeIndex: number;
  nextColor: () => void
  scribbleMode: boolean
  cursor: boolean;
  freezeSelected: () => void;
  freezeToggle: boolean;
  [key: string]: any;

}

const sessionName = () => {
  return generate({exactly: 3, join: "-", minLength: 3})
}
export const createDisplayOptions = (): DisplayOptions => {
  return {
    palette_name: 'red_black_white',
    size: 10,
    activeColor: palettes['red_black_white'].mids[0],
    activeColorIndex: 1,
    zoom: 1,
    undoLookback: 0,
    paused: false,
    outlineOnly: false,
    outlineWeight: 5,
    shapeType: "circle",
    shapeTypeIndex: 0,
    nextColor: () => {
      return undefined
    },
    freezeSelected: () => {
      return undefined
    },
    freezeToggle: false,
    ['Auto']: false,
    ['Scene Name']: sessionName(),
    scribbleMode: false,
    cursor: false,
  }
}

export const createGui = (opts: DisplayOptions, onChange?: (name: keyof DisplayOptions, value: any) => void) => {
  var gui = new GUI();
  // gui.remember(opts);

  // gui.add(opts, 'displayOutline');
  // gui.add(opts, 'explode');
  //
  gui.add(opts, 'size').min(3).max(75).step(1).name('Size (Scroll)');
  gui.add(opts, 'zoom').min(1).max(10).step(.001).onChange((value) => {
    onChange && onChange('zoom', value)
  }).name('Zoom');
  // gui.add(opts, 'height').step(5); // Increment amount
  //
  // // Choose from accepted values

  gui.add(opts, 'shapeType', shapeTypesValues).onChange((value) => {
    const idx = shapeTypesValues.indexOf(value)
    opts.shapeTypeIndex = idx;
  }).name('Shape (Shift+D)');
  gui.add(opts, 'outlineWeight').min(1).max(100).name('Outline Weight');
  gui.add(opts, 'outlineOnly').name('Outline').name('Outline (Shift+O)');
  gui.add(opts, 'scribbleMode').name('Scribble').name('Scribble Mode (Shift+E)');

  gui.add(opts, 'paused').onChange((value) => {
    onChange && onChange('paused', value)
  }).name('Pause (Shift+P)');
  gui.add(opts, 'freezeSelected').name('Freeze (Shift+F)').onChange(() => {
    // opts.freezeToggle = !opts.freezeToggle
    onChange && onChange('freezeSelected', true)
  })
  //
  // // Choose from named values
  // gui.add(opts, 'speed', { Stopped: 0, Slow: 0.1, Fast: 5 } );
  //
  var f1 = gui.addFolder('Colors');
  f1.add(opts, 'palette_name', Array.from(Object.keys(palettes))).name('Palette')
  const activeColorHandler = f1.addColor(opts, 'activeColor').onChange((value) => {
    console.log('updated color to 2 ', opts.activeColor)
    onChange && onChange('activeColor', opts.activeColor)
  }).name('Color');
  opts['nextColor'] = function () {
    const total = palettes[opts.palette_name].bg.length + palettes[opts.palette_name].mids.length + palettes[opts.palette_name].highlights.length
    opts.activeColorIndex = (opts.activeColorIndex + 1) % total
    if (opts.activeColorIndex < palettes[opts.palette_name].bg.length) {
      opts.activeColor = palettes[opts.palette_name].bg[opts.activeColorIndex]
    } else if (opts.activeColorIndex < palettes[opts.palette_name].bg.length + palettes[opts.palette_name].mids.length) {
      opts.activeColor = palettes[opts.palette_name].mids[opts.activeColorIndex - palettes[opts.palette_name].bg.length]
    } else {
      opts.activeColor = palettes[opts.palette_name].highlights[opts.activeColorIndex - palettes[opts.palette_name].bg.length - palettes[opts.palette_name].mids.length]
    }
  };
  const nextColorController = f1.add(opts, 'nextColor').name('Next Color Shift+V')
  nextColorController.onChange(function (value) {
    activeColorHandler.updateDisplay()
    nextColorController.updateDisplay()
    console.log('updated color to ', opts.activeColor)
    onChange && onChange('activeColor', opts.activeColor)
  });


  f1.open()
  gui.add(opts, 'Scene Name');
  gui.add(opts, 'Auto').onChange((value) => {
    onChange && onChange('Auto', value)
  });

  var debugInfo = gui.addFolder('Dev');
  debugInfo.close()
  debugInfo.add(opts, 'cursor').name("Cursor")
  // f1.addColor(opts, 'color1');
  // f1.addColor(opts, 'color2');
  // f1.addColor(opts, 'color3');
  //
  // var f2 = gui.addFolder('Another Folder');
  // f2.add(opts, 'noiseStrength');
  //
  // var f3 = f2.addFolder('Nested Folder');
  // f3.add(opts, 'growthSpeed');
  //
  // opts['Button with a long description'] = function () {
  //   console.log('Button with a long description pressed');
  // };
  // gui.add(opts, 'Button with a long description');
  return gui
}